import axios from "axios";

const normalizeResults = ({ common, branded }) => {
  const parse = (res) =>
    res
      .map((data) => {
        const { serving_unit: servingUnit, serving_qty: servingQty } = data;
        const carbData = data.full_nutrients?.find(
          (x) => x.attr_id === 205 // field id for carbs
        )?.value;
        const carbs = carbData && Math.round(carbData);

        return {
          data,
          carbs,
          servingQty,
          servingUnit,
          name: data.food_name,
        };
      })
      .filter((x) => x.carbs)
      .slice(0, 5);

  const brandedResults = parse(branded);
  const commonResults = parse(common);
  return [...commonResults, ...brandedResults];
};

const search = (query, callback, clientId, clientKey) => {
  if (process.env.RAILS_ENV === "test") return;
  // https://docs.google.com/document/d/1_q-K-ObMTZvO0qUEAxROrN3bwMujwAN25sLHwJzliK0/edit#heading=h.8jxbyoixqjn
  const options = {
    headers: {
      "x-app-id": clientId,
      "x-app-key": clientKey,
    },
  };
  const queryOptions = {
    common: true,
    self: false,
    branded: true,
    branded_region: 1,
    detailed: true,
  };

  axios
    .post(
      "https://trackapi.nutritionix.com/v2/search/instant",
      { query, ...queryOptions },
      options
    )
    .then((response) => {
      const results = normalizeResults(response?.data);
      callback(results);
    });
};

export { search };
