import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'dropdown', 'list', 'count' ]

  initialize() {
    $(this.dropdownTarget).on('show.bs.dropdown', () => {
      if (this.hasCountTarget) {
        $(this.countTarget).remove();
      }

      $.get(this.element.dataset.url, (response) => {
        $(this.listTarget).replaceWith(response);
      });
    });
  }
}
