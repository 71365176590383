import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggler", "nav" ]

  initialize() {
    if (this.hasTogglerTarget && this.hasNavTarget) {
      this.$toggler = $(this.togglerTarget);
      this.$nav = $(this.navTarget);
    }
  }

  onToggle() {
    if (this.$nav.hasClass('show')) {
      this.$nav.removeClass('show');
      this.$toggler.removeClass('active');
      $('body').removeClass('mobile-menu');
    } else {
      const maxHeight = $(window).height() - $(this.element).outerHeight();
      this.$nav.css('height', maxHeight + 'px');
      this.$nav.addClass('show');
      this.$toggler.addClass('active');
      $('body').addClass('mobile-menu');
    }
  }
}
