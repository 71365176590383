import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'svg' ]

  initialize() {
    this.$el = $(this.element);

    this.$el.on('click', () => {
      this.$el.addClass('active');
      $(this.svgTarget).toggleClass('active');
      $.post(this.$el.data('url'), (response) => {
        this.$el.html(response);
      });
    });
  }
}
