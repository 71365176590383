// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_CURRENT_ENV || 'production',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.0,
  });
}

import 'bootstrap'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'jquery-form/dist/jquery.form.min'
import flatpickr from "flatpickr"

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import '../stylesheets/application.scss'

import "controllers"

$(window).on('scroll', function() {
  var scrollHeight = $(document).height();
  var scrollPos = $(window).height() + $(window).scrollTop();
  if(((scrollHeight - 300) >= scrollPos) / scrollHeight == 0){
    $('html').trigger('load-more');
  }
});

$.fn.initFlatpickr = function() {
  $(this).find('.flatpickr').flatpickr({
    altInput: true,
    altFormat: "F j, Y",
    dateFormat: "Y-m-d",
    maxDate: new Date()
  });
}

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

$(document).on('turbolinks:load', () => {
  const $refreshTimezone = $("meta[name='refresh-timezone']");
  const tz = dayjs.tz.guess();
  if ($refreshTimezone.length && tz) {
    $.ajax({
      url: $refreshTimezone.attr('content'),
      method: 'PUT',
      data: { timezone: tz }
    });
  }
});
