import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'select', 'formGroup' ]

  initialize() {
    const answerOptions = JSON.parse(this.element.dataset.answers);

    this.$select = $(this.selectTarget);
    this.$formGroups = $(this.formGroupTargets);

    this.$select.change(() => {
      const val = this.$select.val();

      if (val && val.length > 0) {
        console.log('here');
        this.$formGroups
          .find('select')
          .prop('disabled', false)
          .val('')
          .trigger('change')
          .html('<option></option>' + answerOptions[val].map(answer => `<option value="${answer}">${answer}</option>`).join() + '<option value="other">Other</option>');
      } else {
        this.$formGroups.find('select').prop('disabled', true).html('').trigger('change');
        this.$formGroups.find('input').val('');
      }
    });
  }
}
