import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'schedule', 'select' ]

  initialize() {
  }

  loadCoaches(coaches) {
    this.selectTarget.innerHTML = coaches.map(coach => `<option value="${coach.url}">${coach.name}</option>`).join('')
    this.setCoach(coaches[0].url)
  }

  display() {
    $(this.element).modal('show')
  }

  setCoach(url) {
    let onclick = this.scheduleTarget.getAttribute('onclick')
    let newOnclick = onclick.replace(/(.*)url: '[^\?]*(\??.*'.*)/, `$1url: '${url}$2`)
    this.scheduleTarget.setAttribute('onclick', newOnclick)
  }

  updateCoach(event) {
    let url = event.currentTarget.value
    this.setCoach(url)
  }
}
