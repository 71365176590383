import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "expandButton", "outerContainer"]
  toggle ({ currentTarget }) {
    if (this.outerContainerTarget.classList.contains("expanded")) {
      this.outerContainerTarget.classList.remove("expanded")
    } else {
      this.outerContainerTarget.classList.add("expanded")
    }
  }
}
