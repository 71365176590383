import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  onSubmit() {
    const $form = $(this.element).find('form');
    $form.ajaxSubmit({
      success: (response, statusText, xhr) => {
        const contentType = xhr.getResponseHeader('Content-Type');
        if (contentType && contentType.indexOf('text/html') >= 0) {
          $form.replaceWith(response);
        }
      }
    })
  }
}
