import { Controller } from "stimulus"

export default class extends Controller {

  initialize() {
    const selectedOption = $(this.element).find(":selected")
    $(this.element).find('.coach').addClass('d-none')
    $(this.element).find('.coach[data-coach="' + selectedOption.data("coachid") + '"]').removeClass('d-none').addClass('d-flex')
  }

  onSelect(e) {
    const selectedOption = $(e.target.children).filter(function() { return this.value === e.target.value})
    $(this.element).find('.coach').addClass('d-none')
    $(this.element).find('.coach[data-coach="' + selectedOption.data("coachid") + '"]').removeClass('d-none').addClass('d-flex')
  }
}
