import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'editor' ]

  onReply(e) {
    if (this.hasEditorTarget) {
      const $comment = $(e.currentTarget).closest('.t-comment');
      const $editor = $(this.editorTarget);

      // if it's a reply-to-a-reply, add info for mention...
      if ($comment.parents('.t-comment').length) {
        $editor.attr('data-user-id', $comment.data('userId'));
        $editor.attr('data-user-name', $comment.data('userName'));
      }

      $editor.removeClass('hidden').find('.t-post-editor-placeholder').click();
    } else {
      $(this.element).find('.t-post-editor').trigger('focus-editor');
    }

  }
}
