import { Controller } from "stimulus"
import Glide from '@glidejs/glide';

export default class extends Controller {
  static targets = [ 'bulletcontainer' ]

  initialize() {
    const glide = new Glide(this.element).mount();
    this.layout();
  }

  layout() {
    const contentHeight = $(this.element).find('.glide__slides').height() - $(this.element).find('.text-center:visible').height()
    const bottomPos = contentHeight - 30
    $(this.element).find('.glide__bullets').css('bottom', `${bottomPos}px`)
  }
}
