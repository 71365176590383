import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    console.log('yeas')
    $(this.element).modal('show')
  }

  disconnect() {
    $(this.element).modal('hide');
  }

}