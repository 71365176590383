import { Controller } from "stimulus"
import Quill from "quill";
import "quill-mention";

const bytes = require('bytes');

export default class extends Controller {
  static targets = [ 'header', 'block', 'attachments', 'uploadButton', 'tags' ]

  initialize() {
    this.$el = $(this.element);
    this.IMAGE_MAX = 5;
    this.tagIds = [];

    this.constructor.targets.forEach(target => {
      if (this['has' + target.charAt(0).toUpperCase() + target.slice(1) + 'Target']) {
        this[`$${target}`] = $(this[`${target}Target`]);
      }
    });

    this.quill = new Quill(this.blockTarget, {
      modules: {
        toolbar: false,
        mention: {
          defaultMenuOrientation: 'top',
          isolateCharacter: true,
          minChars: 1,
          allowedChars: /[a-zA-Z\s]/,
          source: async (searchTerm, renderList) => {
            const matchedPeople = await this.searchUsers(searchTerm);
            renderList(matchedPeople);
          },
          renderItem: (item, searchTerm) => {
            return `<div class="t-mention-option"><div class="t-mention-image">${item.image}</div> ${item.value}</div>`
          }
        }
      },
      formats: ['mention'],
      placeholder: this.$el.data('placeholder')
    });

    this.quill.on('selection-change', (range) => {
      // on blur/focus
      if (!range) {
        if (this.quill.getLength() <= 1 && this.attachments.filter(att => att["_destroy"] !== true).length == 0) {
          this.$el.removeClass('has-content');
        }
      } else {
        this.$el.addClass('has-content');
      }
    });

    this.$header.click(() => {
      this.quill.focus();
    });

    this.$el.on('focus-editor', this.focusEditor.bind(this));

    this.$el.on('click', '.t-post-editor-image', e => {
      const imageIndex = $(e.currentTarget).index();
      if (imageIndex < this.attachments.filter(att => att["_destroy"] !== true).length) {
        if (this.$el.data('mode') == "editing") {
          const attachmentId = $(e.currentTarget).find('.t-post-editor-x').attr('attachment-id')
          const attachmentToDestroy = this.attachments.find(att => att.id == attachmentId)
          attachmentToDestroy["_destroy"] = true
          this.renderAttachments();
        } else {
          this.attachments.splice(imageIndex, 1);
        }
        if (this.attachments.filter(att => att["_destroy"] !== true).length == 0) {
          this.attachmentType = null;
        }
        this.renderAttachments();
      } else {
        this.onAddImage();
      }
    });

    this.$el.on('click', '.t-attachment-video .t-post-editor-x', (event) => {
      if (this.$el.data('mode') === "editing") {
        this.attachments[0]["_destroy"] = true;
        this.attachmentType = null;
        this.renderAttachments();
      } else {
        this.attachments = [];
        this.renderAttachments();
      }
    });

    this.resetEditor();

    if (this.$el.data('mode') === "editing") {
      this.quill.setContents(this.$el.data("content"))
      this.attachments = this.$el.data("attachments")
      this.tagIds = this.$el.data("tagIds")
      if (this.attachments.length > 0) {
        if (this.attachments[0].mime_type == "application/pdf") {
          this.attachmentType = "file";
        } else {
          this.attachmentType = this.attachments[0].mime_type.split('/')[0];
        }
      }
      this.renderAttachments();
      this.renderTags();
    }
  }

  connect() {
    if (this.element.dataset.autofocus) {
      if (this.element.dataset.userId && this.element.dataset.userName) {
        this.quill.setContents([
          {"insert": {"mention": {"id": this.element.dataset.userId, "value": this.element.dataset.userName, "denotationChar": "@"}}},
          {"insert": " "}
         ])
        this.quill.setSelection(999,0);
      }
      this.focusEditor();
    }
  }

  focusEditor() {
    this.quill.focus();
    window.scrollTo({ top: this.$el.offset().top - 300 });
  }

  resetEditor() {
    this.attachments = [];
    this.attachmentType = null;
    this.tagIds = [];
    this.renderAttachments();
    this.renderTags();
  }

  async searchUsers(q) {
    return $.get({
      url: this.$el.data('searchUrl'),
      data: { q: q }
    });
  }

  uploadComplete(files) {
    if (files.filesUploaded.length === 1) {
      this.attachments.push({
        url: files.filesUploaded[0].url,
        mime_type: files.filesUploaded[0].mimetype,
        filename: files.filesUploaded[0].filename.replaceAll(/[^\w\.]/g, ''),
        filesize: files.filesUploaded[0].size
      });
      this.renderAttachments();
    }
  }

  videoUploadComplete(files) {
    if (files.filesUploaded.length === 1) {
      const attachment = {
        url: files.filesUploaded[0].url,
        mime_type: files.filesUploaded[0].mimetype,
        filename: files.filesUploaded[0].filename.replaceAll(/[^\w\.]/g, ''),
        filesize: files.filesUploaded[0].size,
      }

      if (files.filesUploaded[0].workflows) {
        const workflow = Object.keys(files.filesUploaded[0].workflows)[0]
        const jobid = files.filesUploaded[0].workflows[workflow].jobid
        if (jobid) {
          attachment.filestack_job_id = jobid
        }
      }

      this.attachments.push(attachment);
      this.renderAttachments();
    }
  }

  // Rendering

  renderTags() {
    this.tagIds.forEach(tagId => {
      this.$el.find('#tag-' + tagId).addClass('active')
    })
  }

  renderAttachments() {
    $(this.uploadButtonTargets).removeClass('active');

    if (this.attachments.filter(att => att["_destroy"] !== true).length > 0) {
      $(this.uploadButtonTargets).filter(`[data-control=${this.attachmentType}]`).addClass('active');
      this.$attachments.html('').removeClass('hidden');

      if (this.attachmentType == 'image') {
        const templateContents = $('#t-post-templates-image').html();

        this.attachments.forEach(attachment => {
          if (attachment["_destroy"] != true) {
            const $image = $(templateContents).addClass('filled');
            $image.find('img').attr('src', attachment.url);
            $image.find('.t-post-editor-x').attr('attachment-id', attachment.id);
            this.$attachments.append($image);
          }
        });

        if (this.attachments.filter(att => att["_destroy"] !== true).length < this.IMAGE_MAX) {
          this.$attachments.append(templateContents);
        }
      } else if (this.attachmentType == 'video') {
        if (this.attachments[0].mime_type == 'video/mp4') {
          this.attachments.forEach(attachment => {
            if (attachment["_destroy"] !== true) {
              const $video = $($('#t-post-templates-video').html());
              $video.find('source').attr('src', attachment.url).attr('type', attachment.mime_type);
              this.$attachments.append($video);
            }
          })
        } else {
          const $transcodingPlaceholder = $($('#t-post-templates-transcoding-placeholder').html());
          this.$attachments.append($transcodingPlaceholder);
        }
      } else if (this.attachmentType == 'file') {
        const $file = $($('#t-post-templates-file').html());
        $file.attr('href', this.attachments[0].url).attr('download', this.attachments[0].filename);
        const filenameParts = this.attachments[0].filename.split('.');
        const ext = filenameParts.length > 1 ? filenameParts[filenameParts.length - 1] : '';
        const title = filenameParts[0].replace(/_-/g, ' ');
        $file.find('.t-attachment-file-name').text(title);
        $file.find('.t-attachment-file-ext').text(ext.toUpperCase());
        $file.find('.t-attachment-file-size').text(bytes(this.attachments[0].filesize, {unitSeparator: ' ', decimalPlaces: 0}));
        this.$attachments.append($file);
      }
    } else {
      this.$attachments.addClass('hidden');
    }
  }

  // Actions

  onPost() {
    if (this.quill.getLength() > 1) {
      if (this.$el.data('method') === "patch") {
        $.post({
          url: this.$el.data('url'),
          contentType: 'application/json',
          type: "PATCH",
          data: JSON.stringify({
            post: this.quill.getContents(),
            attachments: this.attachments,
            attachment_type: this.attachmentType,
            tag_ids: this.tagIds
          })
        })
      } else {
        $.post({
          url: this.$el.data('url'),
          contentType: 'application/json',
          data: JSON.stringify({
            post: this.quill.getContents(),
            attachments: this.attachments,
            attachment_type: this.attachmentType,
            tag_ids: this.tagIds
          }),
          success: (data, textStatus, xhr) => {
            const contentType = xhr.getResponseHeader('Content-Type');
            if (contentType && contentType.indexOf('application/json') >= 0) {
              this.$el.parent().find('.t-post-comments-empty').remove();
              this.$el.before(data.comment);
              if (data.count) {
                this.$el.closest('.t-post').find('.t-post-replies-count').show().find('span').text(data.count);
              }
              this.quill.setText('');
              this.quill.blur();
              this.resetEditor();
            }
          }
        })
      }
    }
  }

  onAddImage() {
    if (this.attachments.filter(att => att["_destroy"] !== true).length < this.IMAGE_MAX && (this.attachmentType !== 'file' && this.attachmentType !== 'video')) {
      this.attachmentType = 'image';

      // uncomment (and comment the filepicker) to test attaching images without actually uploading
      // this.attachments.push({
      //   url: 'https://cdn.filestackcontent.com/m3OaWbAgTTKbi73ceRBz',
      //   filename: 'jolly.png',
      //   mime_type: 'image/png'
      // })
      // this.renderAttachments();

      filestack_client.picker({
        accept: 'image/*',
        fromSources: ['local_file_system'],
        imageMin: [588, 335],
        imageMax: [1764, 1005],
        maxSize: 10 * 1024 * 1024,
        uploadInBackground: false,
        onUploadDone: this.uploadComplete.bind(this)
      }).open();
    }
  }

  onAddVideo() {
    if (this.attachments.filter(att => att["_destroy"] !== true).length < 1 && (this.attachmentType !== 'image' && this.attachmentType !== 'file')) {
      this.attachmentType = 'video';

      // uncomment (and comment the filepicker) to test attaching videos without actually uploading
      // this.attachments.push({
      //   url: 'https://cdn.filestackcontent.com/HUNg17E4ScmNzOHduPDU',
      //   filename: 'trailer.mp4',
      //   mime_type: 'video/mp4'
      // })
      // this.renderAttachments();
      filestack_client.picker({
        accept: ['video/mp4', 'video/quicktime'],
        fromSources: ['local_file_system'],
        uploadInBackground: false,
        maxSize: 1000 * 1024 * 1024,
        onUploadDone: this.videoUploadComplete.bind(this),
        storeTo: {
          workflows: [this.$el.data('videoConvertWorflowId')]
        }
      }).open();
    }
  }

  onAddFile() {
    if (this.attachments.filter(att => att["_destroy"] !== true).length < 1 && (this.attachmentType !== "image" && this.attachmentType !== "video")) {
      this.attachmentType = 'file';

      // uncomment (and comment the filepicker) to test attaching files without actually uploading
      // this.attachments.push({
      //   url: 'https://cdn.filestackcontent.com/sivw5PiGQaOCgVkx9fD3',
      //   filename: 'test.pdf',
      //   mime_type: 'application/pdf',
      //   filesize: 123456
      // })
      // this.renderAttachments();

      filestack_client.picker({
        accept: 'application/pdf',
        fromSources: ['local_file_system'],
        uploadInBackground: false,
        maxSize: 50 * 1024 * 1024,
        onUploadDone: this.uploadComplete.bind(this)
      }).open();
    }
  }

  onToggleTag() {
    const tagId = event.currentTarget.dataset.tagid;
    $(event.currentTarget).toggleClass("active")
    this.tagIds = this.tagIds.includes(tagId)
            ? this.tagIds.filter(i => i !== tagId) // remove item
            : [ ...this.tagIds, tagId ];
  }

  onToggleTagList() {
    $(event.currentTarget).toggleClass("active")
    $(this.tagsTarget).toggleClass("d-none d-flex my-1")
  }
}
