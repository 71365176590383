import { Controller } from "stimulus"

export default class extends Controller {
  // static targets = ["password", "icon"]
  static targets = ["month", "day", "year"]

  getDaysInMonth(month, year) {
    const monthIdx = month - 1
    const date = new Date(year, monthIdx );
    const days = [];
    while (date.getMonth() === monthIdx) {
      days.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }
    return days;
  }

  restrictDays (event) {
    if (this.monthTarget.value && this.yearTarget.value) {
      const days = this.getDaysInMonth(parseInt(this.monthTarget.value), parseInt(this.yearTarget.value))
      this.dayTarget.max = days.length
      if (parseInt(this.dayTarget.value) > days.length) {
        this.dayTarget.value = days.length
      }
    }
  }
}
