import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  initialize() {
    const $inputs = $(this.element).find('input');

    $inputs.on('paste', (e) => {
      console.log('paste');
      e.preventDefault();

      const paste = (event.clipboardData || window.clipboardData).getData('text');

      if (paste.match(/^\d+$/)) {
        const chars = paste.split('');


        for (let i = 0; i < 4; i++) {
          $inputs.eq(i).val(chars[i]);
        }

        $inputs.blur();
      }
    });

    $inputs.on('keydown', (e) => {
      if (e.which == 8) {
        $(e.currentTarget).val('').blur().prev().focus();
        e.preventDefault();
      }
    });

    $inputs.on('keypress', (e) => {
      e.preventDefault();

      const char = String.fromCharCode(e.which);
      if (!isNaN(parseInt(char))) {
        $(e.currentTarget).val(char).blur().next().focus();
      }
    });
  }
}
