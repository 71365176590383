import { Controller } from "stimulus";
import autocomplete from "autocomplete.js";
import { search } from "../utils/nutritionix";

export default class extends Controller {
  static targets = [
    "name",
    "carbs",
    "quantity",
    "nutritionixData",
    "recent",
    "favorite",
  ];
  static values = {
    clientId: String,
    clientKey: String,
    hasRecent: Boolean,
  };

  connect() {
    this.ac = autocomplete(
      this.nameTarget,
      {
        openOnFocus: true,
        autoselectOnBlur: false,
        templates: {
          dropdownMenu: "#dropdown-template",
          debounce: 200,
        },
      },
      [
        {
          source: (q, callback) =>
            search(q, callback, this.clientIdValue, this.clientKeyValue),
          name: "nutritionix",
          templates: {
            header: "<div class='aa-label'>All Results</div>",
            empty: "<div class='no-results'>No results found</div>",
            suggestion: ({ name, carbs, servingUnit, servingQty }) =>
              `<div class='name'>${name}</div>
                <div class='info'>
                  <div class="serving"><span class="unit"> Serving (${servingUnit})</span><span class="amount">${servingQty}</span></div>
                  <div class='carbs'><span class='unit'> Carbs (grams)</span><span class='amount'>${carbs}</span></div>
                </div>
              </div>`,
          },
        },
      ]
    ).on("autocomplete:selected", this.autocompleteSelect.bind(this));
  }

  autocompleteSelect(_event, suggestion, _dataset, _context) {
    if (!suggestion) return;
    this.onSelect(suggestion);
  }

  onSelect({ data, name, servingQty, servingUnit, carbs }) {
    $(".favorites").addClass("d-none");
    $(".food-inputs").removeClass("d-none");

    this.ac.autocomplete.setVal(name);
    this.carbsTarget.value = carbs;

    this.nutritionixDataTarget.value =
      typeof data === "object" ? JSON.stringify(data) : data;

    if (!this.quantityTarget.value.length) {
      this.quantityTarget.value = 1;
    }

    this.close();
  }

  /* Actions */

  selectRecent({ currentTarget }) {
    const recent = Object.assign({}, currentTarget.dataset);
    this.onSelect(recent);
  }

  selectFavorite({ currentTarget }) {
    const favorite = Object.assign({}, currentTarget.dataset);
    this.onSelect(favorite);
  }

  clickName({ currentTarget }) {
    $(".btn.add-custom").removeClass("d-none");
    if (!currentTarget.value.length && this.hasRecentValue) {
      $(".aa-dropdown-menu").show();
    }
  }

  changeName({ currentTarget }) {
    if (currentTarget.value.length) {
      $(".btn.add-custom").removeClass("d-none");
      $(".favorites").addClass("d-none");
    } else {
      $(".favorites").removeClass("d-none");
      $(".aa-dataset-recent").show();
      $(".aa-dropdown-menu").show();
    }
  }

  close() {
    $(".favorites").addClass("d-none");
    $(".food-inputs").removeClass("d-none");
    $(".btn.add-custom").addClass("d-none");
    this.ac.autocomplete.close();
  }
}
