import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'spinner' ]

  connect() {
    this.loading = false;
    this.hasMore = true;
    this.page = 1;
    $('html').on('load-more', this.onLoadMore.bind(this));
  }

  onLoadMore() {
    if (this.hasMore && !this.loading) {
      this.loading = true;
      if (this.hasSpinnerTarget) {
        $(this.spinnerTarget).show();
      }
      $.get(this.element.dataset.url, { page: this.page + 1 }, (response) => {
        if (this.hasSpinnerTarget) {
          $(this.spinnerTarget).hide();
        }

        if (response && response.trim().length) {
          if (this.hasSpinnerTarget) {
            $(this.spinnerTarget).before(response);
          } else {
            $(this.element).append(response);
          }
          if (window.analytics) {
            analytics.track(this.element.dataset.contenttype + ' Page Loaded', { pageNumber: this.page, url: window.location.href });
          }
          this.page = this.page + 1;
        } else {
          this.hasMore = false;
        }
        this.loading = false;
      });
    }
  }

  disconnect() {
    $('html').off('load-more');
  }
}
