import { Controller } from "stimulus"

export default class extends Controller {
  onClick(e) {
    $(this.element).find('.t-profile-tab').removeClass('active');
    $(this.element).find('.tab').removeClass('active');
    $(e.currentTarget).addClass('active')
    $(this.element).find($(e.currentTarget).data('tab')).addClass('active');
  }
}
