import { Controller } from "stimulus"

export default class extends Controller {
  track ({ currentTarget }) {
    if (window.analytics) {
      const { action, event, controller, glideDir, bsTarget, bsToggle, tabsTarget, ...rest } = currentTarget.dataset
      analytics.track(currentTarget.dataset.event, Object.assign({}, rest, { url: window.location.href }));
    }
  }

  trackFilterEvent({ currentTarget }) {
    if (window.analytics) {
      const { url, courseName, courseId } = currentTarget.dataset
      const tags = new URLSearchParams(new URL(url).search).get('tags')
      const pathname = new URL(url).pathname
      const event = courseId ? "Filter Posts on Course Wall" : "Filter Posts on Community Wall" 
      analytics.track(event, { tags: tags, url: window.location.href, courseName: courseName, courseId: courseId });
    }
  }

  trackPostEvent ({ currentTarget }) {
    if (window.analytics) {
      const eventProperties = { postId: currentTarget.dataset.postId, url: window.location.href }
      if (currentTarget.dataset.postAction) {
        eventProperties.post_action = currentTarget.dataset.postAction
      }
      if (currentTarget.dataset.postUrl) {
        eventProperties.post_url = currentTarget.dataset.postUrl
      }
      analytics.track(currentTarget.dataset.event, eventProperties);
    }
  }

  trackCommentEvent ({ currentTarget }) {
    if (window.analytics) {
      let event = "Comment"
      const { action, ...rest } = currentTarget.dataset
      if (this.element.dataset.postType) {
        event = this.element.dataset.postType
      }
      analytics.track(event, { targetId: currentTarget.dataset.commentId, url: window.location.href });
    }
  }

  trackCommentLike ({ currentTarget }) {
    if (window.analytics) {
      analytics.track(currentTarget.dataset.event, { commentId: currentTarget.dataset.commentId, url: window.location.href });
    }
  }

  trackProfileEvent ({ currentTarget }) {
    if (window.analytics) {
      analytics.track(currentTarget.dataset.event, { userId: currentTarget.dataset.userId, url: window.location.href });
    }
  }

  trackUserEvent ({ currentTarget }) {
    if (window.analytics) {
      analytics.track(currentTarget.dataset.event, { userId: currentTarget.dataset.id, userName: currentTarget.dataset.name,  url: window.location.href});
    }
  }

  trackCourseEvent ({ currentTarget }) {
    if (window.analytics) {
      analytics.track(currentTarget.dataset.event, { courseId: currentTarget.dataset.id, courseTitle: currentTarget.dataset.title, url: window.location.href});
    }
  }

  trackLessonEvent ({ currentTarget }) {
    if (window.analytics) {
      const { action, event, ...rest } = currentTarget.dataset
      analytics.track(currentTarget.dataset.event, Object.assign({}, rest, { url: window.location.href }));
    }
  }

  trackLessonToggleEvent ({ currentTarget }) {
    if (window.analytics) {
      let { action, event, ...rest } = currentTarget.dataset
      action = (currentTarget.parentElement).classList.contains('expanded') ? ' Collapsed' : ' Expanded';
      const eventName = currentTarget.dataset.type + action;
      const eventProperties = Object.assign({}, rest, { url: window.location.href });

      analytics.track(eventName, eventProperties);
    }
  }

  trackHomePageEvent ({ currentTarget }) {
    if (window.analytics) {
      let { action, event, ...rest } = currentTarget.dataset
      analytics.track(currentTarget.dataset.event, rest);
    }
  }

  trackSubscriptionEvent ({ currentTarget }) {
    if (window.analytics) {
      let { action, event, ...rest } = currentTarget.dataset
      analytics.track(currentTarget.dataset.event, rest);
    }
  }
}
