import { Controller } from "stimulus"
import Cleave from 'cleave.js'

export default class extends Controller {
  static targets = []

  initialize() {
    new Cleave(this.element, {
      blocks:[0, 3, 0, 3, 4],
      delimiters: ['(', ')', ' ', '-']
    });
  }
}
