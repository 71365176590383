import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    coaches: Array
  }

  buttonClicked(event) {
    const otherController = this.application.getControllerForElementAndIdentifier($('#ScheduleCallModal').get(0), 'call-schedule-modal')

    otherController.loadCoaches(this.coachesValue)
    otherController.display()
  }
}