import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["textField"]

  initialize() {
    $(this.element).find('select').change((e) => {
      const val = $(e.currentTarget).val();
      if (val == 'other') {
        $(this.textFieldTarget).val('').show();
        $(this.element).addClass('other');
      } else {
        $(this.textFieldTarget).val(val).hide();
        $(this.element).removeClass('other');
      }
    });
  };
}
