import { Controller } from "stimulus"
import videojs from 'video.js';

export default class extends Controller {
  static targets = [ ]

  initialize() {
    // with turbolinks, initialize will run multiple times, generating a warning
    if (!this.element.id) {
      videojs(this.element, {
        controls: true
      });
    }
  }
}
