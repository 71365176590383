import { Controller } from "stimulus"

const dayjs = require('dayjs')

export default class extends Controller {
  static targets = [ ]

  initialize() {
    this.$el = $(this.element);
  }

  connect() {
    // Alright, this is kinda hacky.. if we're not a first-child
    // and our immediate sibling is a .t-post, compare timestamps and
    // insert a date indicator if needed.
    if (this.$el.index() > 0) {
      const $prev = this.$el.prev();
      if ($prev.hasClass('t-post')) {
        const thisDate = dayjs.unix(this.element.dataset.timestamp);
        const prevDate = dayjs.unix($prev.data('timestamp'));

        if (!thisDate.isSame(prevDate, 'day')) {
          this.$el.before(`<div class="t-posts-date"><span>${thisDate.format('MMMM D, YYYY')}</span></div>`);
        }
      }
    }

    if (this.element.dataset.comment) {
      const $comment = this.$el.find(`#comment-${this.element.dataset.comment}`);
      if ($comment.length) {
        $comment.addClass('highlighted');
        window.scrollTo({ top: $comment.offset().top - 200 });
      }
    }
  }

  onHide() {
    $.post(this.$el.data('hideUrl'), response => {
      this.$el.replaceWith(response);
    });
  }

  onPin() {
    $.post(this.$el.data('pinUrl'), response => {
      this.$el.replaceWith(response);
    });
  }

  onFlag(e) {
    e.preventDefault()
    const approved = confirm('By flagging this post, you will be unable to see the post and an administrator will be notified to review it. Are you sure you want to flag this post?')
    if (approved) {
      $.post(this.$el.data('flagUrl'), response => {
        this.$el.remove()

        const isShowPage = window.location.href.includes(this.element.dataset.postId)
        if (isShowPage) {
          const indexPath = window.location.href.replace(`/${this.element.dataset.postId}`, '')
          window.location.href = indexPath
        }
      });
    }
  }
}
