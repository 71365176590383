import { Controller } from "stimulus"
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)

export default class extends Controller {
  static targets = [ ]

  connect() {
    $(this.element).val(dayjs.tz.guess());
  }
}
