import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  onClick() {
    const $editor = $($('#t-post-templates-editor').html());

    // transfer data from the proxy to the actual editor
    $editor.attr('data-url', this.element.dataset.url);
    $editor.attr('data-search-url', this.element.dataset.searchUrl);
    $editor.attr('data-placeholder', this.element.dataset.placeholder);
    $editor.attr('data-user-id', this.element.dataset.userId);
    $editor.attr('data-user-name', this.element.dataset.userName);
    $editor.attr('data-post-type', this.element.dataset.postType);
    $editor.find('.tag-list-toggle').addClass('d-none');

    $editor.attr('data-autofocus', 'true');
    $(this.element).replaceWith($editor[0].outerHTML);
  }
}
