import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'canvas', 'tab' ]

  initialize() {
    const ctx = this.canvasTarget.getContext('2d');

    const colors = ['#56B8D0', '#387FB8', '#1D4A83', '#5B1739', '#C72C39', '#EE943B'];
    const radius = this.canvasTarget.width / 2;
    const data = JSON.parse(this.element.dataset.wheel);

    // draw the sections
    for (let i = 0; i < 6; i++) {
      ctx.fillStyle = colors[i];
      ctx.beginPath();
      ctx.moveTo(radius, radius);
      ctx.arc(radius, radius, (radius * 0.43) + ((radius * 0.57) * data[i] / 100), (((2.0 * i / 6) + 1.5) % 2.0) * Math.PI, (((2.0 * (i + 1) / 6) + 1.5) % 2.0) * Math.PI, false);
      ctx.lineTo(radius, radius);
      ctx.fill();
      ctx.closePath();
    }

    // draw the separator lines
    for (let i = 0; i < 6; i++) {
      const angle = (((2.0 * i / 6) + 1.5) % 2.0) * Math.PI;

      ctx.beginPath();
      ctx.moveTo(radius, radius);
      ctx.arc(radius, radius, radius, angle, angle, false);
      ctx.lineWidth = radius / 45;
      ctx.strokeStyle = 'white';
      ctx.stroke();
      ctx.closePath();
    }

    // draw middle circle
    ctx.beginPath();
    ctx.moveTo(radius, radius);
    ctx.arc(radius, radius, (radius * 0.43) - (radius * 0.089), 0, 2*Math.PI, false);
    ctx.fillStyle = 'white';
    ctx.fill();
    ctx.closePath();

    $(this.tabTargets).on('show.bs.tab', (e) => {
      const $targetContent = $(e.currentTarget.dataset.linkedTab);
      $targetContent.closest('.tab-content').find('.tab-pane').removeClass('active show');
      $targetContent.addClass('active show');
    });
  }
}
