import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["results"];

  onSuccess(event) {
    const [_data, _status, xhr] = event.detail;
    this.resultsTarget.innerHTML = xhr.response;
  }
}
