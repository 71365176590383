import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "imageInput" ]

  connect() {
  }

  imageUploadComplete(files) {
    if (files.filesUploaded.length === 1) {
      var photoUrl = files.filesUploaded[0].url;
      var $img = $(this.element).find('img');
      $img.attr('src', photoUrl).attr('srcset', '');

      if ($(this.element).data('url') && $(this.element).data('url').length) {
        $.post($(this.element).data('url'), { url: photoUrl });
      } else {
        $(this.imageInputTarget).val(photoUrl);
      }
    }
  }

  onUpload() {
    filestack_client.picker({
      accept: 'image/*',
      fromSources: ['local_file_system'],
      imageMin: [412, 412],
      imageMax: [832, 832],
      transformations: { crop: { aspectRatio: 1, force: true } },
      uploadInBackground: false,
      onUploadDone: this.imageUploadComplete.bind(this)
    }).open();
  }
}
