import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'modal', 'button', 'container', 'scroll']

  initialize() {
    this.tagNames = []
    this.sortOrder = null
  }

  showFilters() {
    $('#filterModal').modal('toggle')
  }

  removePinned() {
    const newUrl = new URL(this.buttonTarget.dataset.url)
    newUrl.searchParams.set('pinned', 0)
    this.buttonTarget.setAttribute('data-url', newUrl.toString())
  }

  addPinned() {
    const newUrl = new URL(this.buttonTarget.dataset.url)
    newUrl.searchParams.set('pinned', 1)
    this.buttonTarget.setAttribute('data-url', newUrl.toString())
  }

  onToggleTag() {
    const name = event.currentTarget.dataset.tagname;
    $(event.currentTarget).toggleClass("active")
    this.tagNames = this.tagNames.includes(name)
            ? this.tagNames.filter(i => i !== name) // remove item
            : [ ...this.tagNames, name ];

  }

  onChangeSortOrder() {
    this.sortOrder = event.currentTarget.value
  }

  onFilter() {
    const newUrl = new URL(this.buttonTarget.dataset.url)
    newUrl.searchParams.set('tags', this.tagNames)
    if (this.sortOrder) {
      newUrl.searchParams.set('order', this.sortOrder)
    }

    this.buttonTarget.setAttribute('data-url', newUrl.toString())
    if (this.hasScrollTarget) {
      this.scrollTarget.setAttribute('data-url', newUrl.toString())
    }

    $.get(this.buttonTarget.dataset.url, response => {
      const $response = $(response);
      $('#filterModal').modal('toggle');
      $(this.containerTarget).replaceWith($response);
    })
  }
}
