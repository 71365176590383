import { Controller } from "stimulus"
const dayjs = require('dayjs')

export default class extends Controller {
  static targets = ['dateField','hourField', 'minutesField', 'ampmField']

  connect() {
    if (this.element.dataset.action == 'new') {
      const date = new Date();

      this.dateFieldTarget._flatpickr.setDate(dayjs().startOf('day').toDate());

      if (this.hasHourFieldTarget) {
        $(this.hourFieldTarget).val(date.getHours() % 12);
      }

      if (this.hasMinutesFieldTarget) {
        $(this.minutesFieldTarget).val(date.getMinutes());
      }

      if (this.hasAmpmFieldTarget) {
        $(this.ampmFieldTarget).val(date.getHours() >= 12 ? 'PM' : 'AM');
      }
    }
  }
}
