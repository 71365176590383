import { Controller } from "stimulus"
import Glide from '@glidejs/glide';

export default class extends Controller {
  static targets = [ 'count', 'modal', 'modalCount' ]

  initialize() {
    const glide = new Glide(this.element).mount();
    glide.on('move', i => {
      if (this.hasCountTarget) {
        this.countTarget.textContent = glide.index + 1;
      }
      if (this.hasModalCountTarget) {
        this.modalCountTarget.textContent = glide.index + 1;
      }
    });


    glide.on('move.after', i => {
      const imgUrl = $(this.element).find('.glide__slide--active img').attr('src')
      if (this.hasModalTarget) {
        $(this.modalTarget).find('img').attr('src', imgUrl)
      }
    })
  }

  onHide() {
    $.post(this.$el.data('hideUrl'), response => {
      this.$el.replaceWith(response);
    });
  }

  onPin() {
    $.post(this.$el.data('pinUrl'), response => {
      this.$el.replaceWith(response);
    });
  }
}
