import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["password", "icon"]

  toggle (event) {
    if (this.passwordTarget.type == "text") {
      this.passwordTarget.type = "password"
      this.iconTarget.classList.add("bi-eye-fill")
      this.iconTarget.classList.remove("bi-eye-slash-fill")
    } else {
      this.passwordTarget.type = "text"
      this.iconTarget.classList.remove("bi-eye-fill")
      this.iconTarget.classList.add("bi-eye-slash-fill")
    }
  }
}
