import { Controller } from "stimulus";

export default class extends Controller {

  onClick() {
    this.element.setAttribute("data-schedule", "true");
  }

  initialize() {
    const $credit = $(this.element)

    window.addEventListener("message", function(e) {
      // NOTE: this may cause problems if there are multiple call credits on the page,
      // need to figure out a way to tie the calendly event to a specific call credit
      //  and ONLY update that credit's is_scheduled field...
      const shouldUpdate = $credit.data("schedule")
      if (e.data.event == "calendly.event_scheduled" && shouldUpdate) {
        $.ajax({
          type: 'PATCH',
          url: $credit.data('url'),
          data: {
            call_credit: { is_scheduled: true }
          }
        })
      }
    });
  }
}
