import { Controller } from "stimulus"
import * as bootstrap from 'bootstrap'

export default class extends Controller {
  static targets = [ "tab", "container" ]

  initialize() {
    this.$el = $(this.element);

    $(this.tabTargets).on('click', e => {
      const $tab = $(e.currentTarget);

      if ($tab.hasClass('active') || $tab.hasClass('disabled')) {
        return;
      }

      $(this.tabTargets).removeClass('active');
      $tab.addClass('active');

      this.loadTab();
    });

    if (this.$el.data('autoload')) {
      $(this.tabTargets).filter('.active').removeClass('active').click();
    }

    this.$el.on('tabs:reload', this.loadTab.bind(this));
  }

  loadTab() {
    const activeTab = $(this.tabTargets).filter('.active')[0];

    if (activeTab) {
      $(activeTab).find('.t-notification-count').remove();

      $.get(activeTab.dataset.url, response => {
        const $response = $(response);

        $(this.containerTarget).replaceWith($response);

        // TODO: this stuff should be moved into a global function for initializing chunks of code
        $response.find('[data-bs-toggle="tooltip"]').each((i, e) => {
          new bootstrap.Tooltip(e);
        });

        $response.initFlatpickr();
      });
    }
  }
}
