import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["date", "time"];

  initialize() {
    $(this.dateTarget).text(this.formatDate(this.startDateTime()));
    if (this.endDateTime()) {
      $(this.timeTarget).text([
        this.formatTime(this.startDateTime()),
        this.formatTime(this.endDateTime()),
      ].join(" – "));
    } else {
      $(this.timeTarget).text(this.formatTime(this.startDateTime()));
    }
  }

  formatDate(date) {
    return new Date(date).toLocaleDateString(this.userLocale(),   {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })
  }

  formatTime(dateTime) {
    return new Date(dateTime).toLocaleTimeString(this.userLocale(), {
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short'
    })
  }

  startDateTime(){
    return $(this.element).data('start')
  }

  endDateTime(){
    return $(this.element).data('end')
  }

  userLocale() {
    return Intl.DateTimeFormat().resolvedOptions().locale;
  }
}
