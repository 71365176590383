import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["list", "month"]

  connect() {
    this.events = JSON.parse(this.element.dataset.events)
    this.month = new Date().getMonth() + 1
    this.year = new Date().getFullYear()
    this.renderEvents()
    this.renderMonthName()
  }

  eventsForCurrentMonth () {
    return this.events[this.year][this.month]
  }

  showNextMonth() {
    if (this.month == 12) {
      this.year += 1;
    }
    this.month = (this.month % 12) + 1
    this.renderEvents()
    $(this.monthTarget).text(this.renderMonthName())
  }

  showPrevMonth() {
    if (this.month == 1) {
      this.year -= 1;
      this.month = 12;
    } else {
      this.month -= 1;
    }
    this.renderEvents()
    $(this.monthTarget).text(this.renderMonthName())
  }

  renderEvents() {
    const events = this.eventsForCurrentMonth();
    $(this.listTarget).empty()
    if (events) {
      events.forEach(event => {
        $(this.listTarget).append(this.renderEvent(event))
      })
    }
  }

  renderEvent(event) {
    const $li = $('<li>').addClass('event d-flex align-items-center')
    const $dot = $('<div>').addClass('left').append($('<div>').addClass('dot'))
    const $event = $('<div>').addClass('right').append($('<div>').addClass('text-secondary').text(event.title))
    return $li.append($dot).append($event)
  }

  renderMonthName() {
    const months = [null, "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return months[this.month] + " Events"
  }

}
